import { DataError } from "../../types";
import { api, DetailPath } from "../api";
import { strings } from "../strings/strings";
const { errorStrings } = strings;

export const checkNameIsUnique = async (getPath: string, name: string, currentId = "") => {
    // Check for whitespace
    if (hasLeadingOrTrailingWhitespace(name)) {
        return { error: true, message: errorStrings.validation.whitespaceErr, blocksSaving: true };
    }

    try {
        const response = await api(`${getPath}?name=${name}&id=${currentId}`);
        const responseAsJson = await response.json();
        const isUnique = responseAsJson.isUnique;

        if (response.ok && isUnique) return { error: false };
    } catch (e) {
        console.log("checkNameIsUnique error", e);
    }

    return {
        error: true,
        message: errorStrings.validation.nameAlreadyExists,
        blocksSaving: true,
    };
};

export const ensureUniqId = async (getPath: DetailPath, id: string): Promise<DataError> => {
    if (!id) return { error: false };

    // Check for whitespace
    if (hasLeadingOrTrailingWhitespace(id)) {
        return { error: true, message: errorStrings.validation.whitespaceErr, blocksSaving: true };
    }

    const url = `${getPath}/check-id/${id}`;
    const res = await api(url);

    if (res.ok) return { error: false };

    return {
        error: true,
        message: errorStrings.validation.idAlreadyExists,
        blocksSaving: true,
    };
};

export const ensureUniqUpdatedId = async (
    getPath: DetailPath,
    newId: string,
    existingId: string | undefined
): Promise<DataError> => {
    if (!existingId) {
        throw new Error(
            "ensureUniqUpdatedId must be provided with an existing ID to check against"
        );
    }

    // Check for whitespace
    if (hasLeadingOrTrailingWhitespace(newId)) {
        return { error: true, message: errorStrings.validation.whitespaceErr, blocksSaving: true };
    }

    // If you're not changing the ID, or there's no existing ID, that's fine
    if (newId === existingId) {
        return { error: false };
    }

    // Otherwise, we need to check it
    return ensureUniqId(getPath, newId);
};

const hasLeadingOrTrailingWhitespace = (str: string): boolean => {
    return /^\s|\s$/.test(str);
};
