import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "./store";
import { api } from "../modules/api";
import { RootState } from "./root-reducer";

export type UserObject = {
    userId: string;
    email: string;
    fullName: string;
    isSuperuser?: boolean;
};
export type UserState = {
    userObject?: UserObject;
    authenticating: boolean;
    loginError?: string;
};

export enum AuthenticationState {
    Authenticating = "AUTHENTICATING",
    Unauthenticated = "UNAUTH",
    Authenticated = "AUTH",
}

let initialState: UserState = {
    userObject: undefined,
    authenticating: true,
    loginError: undefined,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserObject | undefined>) {
            state.userObject = action.payload;
        },
        setAuthenticating(state, action: PayloadAction<boolean>) {
            state.authenticating = action.payload;
        },
        setLoginError(state, action: PayloadAction<string | undefined>) {
            state.loginError = action.payload;
        },
    },
});
export const { setUser, setAuthenticating, setLoginError } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.userObject;
export const selectIsSuperuser = (state: RootState) => state.user.userObject?.isSuperuser;
export const selectAuthenticating = (state: RootState) => state.user.authenticating;
export const selectLoginError = (state: RootState) => state.user.loginError;
export const selectAuthenticationState = (state: RootState) => {
    if (state.user.authenticating) return AuthenticationState.Authenticating;
    return Boolean(state.user.userObject)
        ? AuthenticationState.Authenticated
        : AuthenticationState.Unauthenticated;
};

export function checkAuthenticated(): AppThunk {
    return async (dispatch, getState) => {
        // If already authenticating/logged in, just return.
        const existingUser = selectUser(getState());
        if (existingUser) return;

        dispatch(setAuthenticating(true));
        try {
            // Check authenticated using the django backend
            const user = await api("auth/session-check-authenticated/", undefined).then(d =>
                d.json()
            );

            if (user.email) {
                dispatch(setUser(user));
            } else {
                dispatch(setUser(undefined));
            }
        } catch (e) {
            console.error(e);
        }
        dispatch(setAuthenticating(false));
    };
}

export function attemptLogin(email: string, password: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoginError(undefined));
        dispatch(setAuthenticating(true));

        try {
            const userResponse = await api(`auth/session-login/`, {
                method: "POST",
                body: JSON.stringify({ email, password }),
            });
            if (!userResponse.ok) throw new Error("Login failed");

            const responseBody = await userResponse.json();
            const userObj = responseBody.userObj;

            dispatch(setUser(userObj));
            dispatch(setAuthenticating(false));

            return Boolean(userObj);
        } catch (error) {
            dispatch(setAuthenticating(false));

            if (error.response?.status === 403) {
                dispatch(setLoginError("You do not have permission to log in."));
            } else {
                dispatch(setLoginError("Incorrect username/email or password"));
            }
        }

        return false;
    };
}

export function logout() {
    return async (dispatch: AppDispatch) => {
        await api("auth/session-logout/", { method: "GET" }).then(d => d.json());
        await dispatch(setUser(undefined));
        window.location.href = "/login";
    };
}

export default userSlice;
